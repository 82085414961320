import * as React from 'react';
import { AboutBody } from '@containers';
import { Layout } from '@components';
import { graphql } from 'gatsby';
import { SEO } from '../components/seo';

const About = ({ data }) => {
  if (!data?.allMarkdownRemark?.edges?.[0]) {
    return '';
  }
  return (
    <Layout>
      <AboutBody pageData={data?.allMarkdownRemark?.edges?.[0]?.node?.frontmatter} />
    </Layout>
  );
};

export default About;

export const Head = ({ data }) => {
  return <SEO {...data?.allMarkdownRemark?.edges?.[0]?.node?.frontmatter?.seo} />;
};

export const aboutPageQuery = graphql`
  query AboutPageQuery {
    allMarkdownRemark(filter: { frontmatter: { containerPath: { eq: "aboutBody/AboutBody" } } }) {
      edges {
        node {
          frontmatter {
            containerPath
            aboutHeroContainer {
              heroBg
              heroBgIcon
              pageName
              title
            }
            aboutHighlightedText {
              bgImage
              heading
              textFirst
              textSecond
            }
            aboutSlider {
              sliderHeading
              sliderText
              slides {
                slideHeading
                slideText
              }
            }
            aboutTextArea {
              heading1
              text1
            }
            aboutMeetTheFounder {
              description
              image
              name
              title
            }
            aboutTextArea2 {
              heading
              text
              image
            }
            aboutTextArea3 {
              text
              name
            }
            seo {
              title
              favicon
              meta {
                name
                content
              }
            }
          }
        }
      }
    }
  }
`;
